import queryString from 'query-string';
import { call } from 'redux-saga/effects';
import { API_URL } from '../../../config';
import { requestJson } from '../../../utils/request';
import { addIncompleteSurvey, getIncompleteSurvey, removeIncompleteSurvey } from './incompleteSurveys';
import { getWidgetStore } from '../../../../../feedback-web/containers/Widget/helpers/widgetStores';

const sendFeedback = function* (feedback, rawResponse, isIncomplete) {
  const qs = queryString.parse(window.location.search);
  const headers = {
    Accept: 'application/json, text/plain, */*',
    'Content-Type': 'application/json',
  };

  headers['X-COMPLETED'] = true;

  if (qs.sdi && qs.sci) {
    headers['X-SDI'] = qs.sdi;
    headers['X-SCI'] = qs.sci;
  } else if (qs.edi && qs.eci) {
    headers['X-EDI'] = qs.edi;
    headers['X-ECI'] = qs.eci;
  } else if (qs.ldi && qs.lci) {
    headers['X-LDI'] = qs.ldi;
    headers['X-LCI'] = qs.lci;
  } else if (qs.wdi && qs.wci) {
    headers['X-WDI'] = qs.wdi;
    headers['X-WCI'] = qs.wci;
  } else if (qs.pndi && qs.pnci) {
    headers['X-PNDI'] = qs.pndi;
    headers['X-PNCI'] = qs.pnci;
  } else if (Object.keys(getWidgetStore(feedback.node_id)).length) {
    headers['X-WWID'] = feedback.node_id;
  }

  const incompleteSurvey = getIncompleteSurvey(feedback.node_id, qs.sdi, qs.edi, qs.ldi, qs.wdi, qs.pndi);

  if (incompleteSurvey) {
    headers['X-FID'] = incompleteSurvey.feedbackId;
  }

  if (isIncomplete) {
    headers['X-COMPLETED'] = false;
  }

  const response = yield call(requestJson, `${API_URL}/v1/feedbacks`, {
    method: 'POST',
    headers,
    body: JSON.stringify(feedback),
    rawResponse,
  });

  if (isIncomplete) {
    const responseBody = JSON.parse(response.responseText);

    if (incompleteSurvey && incompleteSurvey.feedbackId !== responseBody.id) {
      removeIncompleteSurvey(incompleteSurvey.feedbackId);
      addIncompleteSurvey(responseBody.id, feedback.node_id, feedback.flow_id);
    }
  }

  return response;
};

export default function* (feedback, rawResponse = false, isIncomplete = false) {
  const response = yield call(sendFeedback, feedback, rawResponse, isIncomplete);
  return response;
}
